define("discourse/plugins/discourse-chat-integration/admin/components/rule-row", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "discourse/lib/ajax-error", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _ajaxError, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <tr>
    <td>
      {{@rule.filterName}}
    </td>
  
    <td>
      {{#if this.isCategory}}
        {{#if @rule.category}}
          {{category-link @rule.category allowUncategorized="true" link="false"}}
        {{else}}
          {{i18n "chat_integration.all_categories"}}
        {{/if}}
      {{else if this.isMention}}
        {{i18n "chat_integration.group_mention_template" name=@rule.group_name}}
      {{else if this.isMessage}}
        {{i18n "chat_integration.group_message_template" name=@rule.group_name}}
      {{/if}}
    </td>
  
    <td>
      {{#if this.siteSettings.tagging_enabled}}
        {{#if @rule.tags}}
          {{@rule.tags}}
        {{else}}
          {{i18n "chat_integration.all_tags"}}
        {{/if}}
      {{/if}}
    </td>
  
    <td>
      <DButton
        @icon="pencil-alt"
        @title="chat_integration.rule_table.edit_rule"
        @action={{fn @edit @rule}}
        class="edit"
      />
  
      <DButton
        @icon="far-trash-alt"
        @title="chat_integration.rule_table.delete_rule"
        @action={{fn this.delete @rule}}
        class="delete"
      />
    </td>
  </tr>
  */
  {
    "id": "Uw2xDtLy",
    "block": "[[[10,\"tr\"],[12],[1,\"\\n  \"],[10,\"td\"],[12],[1,\"\\n    \"],[1,[30,1,[\"filterName\"]]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,\"td\"],[12],[1,\"\\n\"],[41,[30,0,[\"isCategory\"]],[[[41,[30,1,[\"category\"]],[[[1,\"        \"],[1,[28,[35,1],[[30,1,[\"category\"]]],[[\"allowUncategorized\",\"link\"],[\"true\",\"false\"]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,2],[\"chat_integration.all_categories\"],null]],[1,\"\\n\"]],[]]]],[]],[[[41,[30,0,[\"isMention\"]],[[[1,\"      \"],[1,[28,[35,2],[\"chat_integration.group_mention_template\"],[[\"name\"],[[30,1,[\"group_name\"]]]]]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"isMessage\"]],[[[1,\"      \"],[1,[28,[35,2],[\"chat_integration.group_message_template\"],[[\"name\"],[[30,1,[\"group_name\"]]]]]],[1,\"\\n    \"]],[]],null]],[]]]],[]]],[1,\"  \"],[13],[1,\"\\n\\n  \"],[10,\"td\"],[12],[1,\"\\n\"],[41,[30,0,[\"siteSettings\",\"tagging_enabled\"]],[[[41,[30,1,[\"tags\"]],[[[1,\"        \"],[1,[30,1,[\"tags\"]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,2],[\"chat_integration.all_tags\"],null]],[1,\"\\n\"]],[]]]],[]],null],[1,\"  \"],[13],[1,\"\\n\\n  \"],[10,\"td\"],[12],[1,\"\\n    \"],[8,[39,3],[[24,0,\"edit\"]],[[\"@icon\",\"@title\",\"@action\"],[\"pencil-alt\",\"chat_integration.rule_table.edit_rule\",[28,[37,4],[[30,2],[30,1]],null]]],null],[1,\"\\n\\n    \"],[8,[39,3],[[24,0,\"delete\"]],[[\"@icon\",\"@title\",\"@action\"],[\"far-trash-alt\",\"chat_integration.rule_table.delete_rule\",[28,[37,4],[[30,0,[\"delete\"]],[30,1]],null]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@rule\",\"@edit\"],false,[\"if\",\"category-link\",\"i18n\",\"d-button\",\"fn\"]]",
    "moduleName": "discourse/plugins/discourse-chat-integration/admin/components/rule-row.hbs",
    "isStrictMode": false
  });
  let RuleRow = _exports.default = (_class = class RuleRow extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "siteSettings", _descriptor, this);
    }
    get isCategory() {
      return this.args.rule.type === "normal";
    }
    get isMessage() {
      return this.args.rule.type === "group_message";
    }
    get isMention() {
      return this.args.rule.type === "group_mention";
    }
    delete(rule) {
      rule.destroyRecord().then(() => this.args.refresh()).catch(_ajaxError.popupAjaxError);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "delete", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RuleRow);
});