define("discourse/plugins/discourse-chat-integration/admin/controllers/admin-plugins-chat-integration-provider", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse/plugins/discourse-chat-integration/admin/components/modal/channel-error", "discourse/plugins/discourse-chat-integration/admin/components/modal/edit-channel", "discourse/plugins/discourse-chat-integration/admin/components/modal/edit-rule", "discourse/plugins/discourse-chat-integration/admin/components/modal/test-integration"], function (_exports, _controller, _object, _service, _channelError, _editChannel, _editRule, _testIntegration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let AdminPluginsChatIntegrationProvider = _exports.default = (_class = class AdminPluginsChatIntegrationProvider extends _controller.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "modal", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
    }
    get anyErrors() {
      let anyErrors = false;
      this.model.channels.forEach(channel => {
        if (channel.error_key) {
          anyErrors = true;
        }
      });
      return anyErrors;
    }
    async triggerModal(modal, model) {
      await this.modal.show(modal, {
        model: {
          ...model,
          admin: true
        }
      });
      this.refresh();
    }
    createChannel() {
      return this.triggerModal(_editChannel.default, {
        channel: this.store.createRecord("channel", {
          provider: this.model.provider.id,
          data: {}
        }),
        provider: this.model.provider
      });
    }
    editChannel(channel) {
      return this.triggerModal(_editChannel.default, {
        channel,
        provider: this.model.provider
      });
    }
    testChannel(channel) {
      return this.triggerModal(_testIntegration.default, {
        channel
      });
    }
    createRule(channel) {
      return this.triggerModal(_editRule.default, {
        rule: this.store.createRecord("rule", {
          channel_id: channel.id,
          channel
        }),
        channel,
        provider: this.model.provider,
        groups: this.model.groups
      });
    }
    editRuleWithChannel(rule, channel) {
      return this.triggerModal(_editRule.default, {
        rule,
        channel,
        provider: this.model.provider,
        groups: this.model.groups
      });
    }
    showError(channel) {
      return this.triggerModal(_channelError.default, {
        channel
      });
    }
    refresh() {
      this.send("refreshProvider");
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "createChannel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "createChannel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editChannel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "editChannel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "testChannel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "testChannel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createRule", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "createRule"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editRuleWithChannel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "editRuleWithChannel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showError", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "showError"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refresh", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "refresh"), _class.prototype)), _class);
});