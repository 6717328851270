define("discourse/plugins/discourse-chat-integration/admin/components/modal/test-integration", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "I18n", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _ajax, _ajaxError, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    {{on "submit" this.send}}
    @title={{i18n "chat_integration.test_modal.title"}}
    @closeModal={{@closeModal}}
    @flash={{this.flash}}
    @flashType="success"
    @tagName="form"
    id="chat_integration_test_modal"
  >
    <:body>
      <table>
        <tbody>
          <tr class="input">
            <td class="label">
              <label for="channel">
                {{i18n "chat_integration.test_modal.topic"}}
              </label>
            </td>
            <td>
              <ChooseTopic @topicChangedCallback={{this.newTopicSelected}} />
            </td>
          </tr>
        </tbody>
      </table>
    </:body>
  
    <:footer>
      <ConditionalLoadingSpinner @condition={{this.loading}}>
        <DButton
          @action={{this.send}}
          @label="chat_integration.test_modal.send"
          @disabled={{not this.topicId}}
          type="submit"
          id="send-test"
          class="btn-primary btn-large"
        />
        <DButton
          @action={{@closeModal}}
          @label="chat_integration.test_modal.close"
          class="btn-large"
        />
      </ConditionalLoadingSpinner>
    </:footer>
  </DModal>
  */
  {
    "id": "VO8ZQcDX",
    "block": "[[[8,[39,0],[[24,1,\"chat_integration_test_modal\"],[4,[38,2],[\"submit\",[30,0,[\"send\"]]],null]],[[\"@title\",\"@closeModal\",\"@flash\",\"@flashType\",\"@tagName\"],[[28,[37,1],[\"chat_integration.test_modal.title\"],null],[30,1],[30,0,[\"flash\"]],\"success\",\"form\"]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,\"table\"],[12],[1,\"\\n      \"],[10,\"tbody\"],[12],[1,\"\\n        \"],[10,\"tr\"],[14,0,\"input\"],[12],[1,\"\\n          \"],[10,\"td\"],[14,0,\"label\"],[12],[1,\"\\n            \"],[10,\"label\"],[14,\"for\",\"channel\"],[12],[1,\"\\n              \"],[1,[28,[35,1],[\"chat_integration.test_modal.topic\"],null]],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,\"td\"],[12],[1,\"\\n            \"],[8,[39,3],null,[[\"@topicChangedCallback\"],[[30,0,[\"newTopicSelected\"]]]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,4],null,[[\"@condition\"],[[30,0,[\"loading\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,5],[[24,1,\"send-test\"],[24,0,\"btn-primary btn-large\"],[24,4,\"submit\"]],[[\"@action\",\"@label\",\"@disabled\"],[[30,0,[\"send\"]],\"chat_integration.test_modal.send\",[28,[37,6],[[30,0,[\"topicId\"]]],null]]],null],[1,\"\\n      \"],[8,[39,5],[[24,0,\"btn-large\"]],[[\"@action\",\"@label\"],[[30,1],\"chat_integration.test_modal.close\"]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"on\",\"choose-topic\",\"conditional-loading-spinner\",\"d-button\",\"not\"]]",
    "moduleName": "discourse/plugins/discourse-chat-integration/admin/components/modal/test-integration.hbs",
    "isStrictMode": false
  });
  let TestIntegration = _exports.default = (_class = class TestIntegration extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "loading", _descriptor, this);
      _initializerDefineProperty(this, "flash", _descriptor2, this);
      _initializerDefineProperty(this, "topicId", _descriptor3, this);
    }
    async send() {
      this.loading = true;
      try {
        await (0, _ajax.ajax)("/admin/plugins/chat-integration/test", {
          data: {
            channel_id: this.args.model.channel.id,
            topic_id: this.topicId
          },
          type: "POST"
        });
        this.loading = false;
        this.flash = _I18n.default.t("chat_integration.test_modal.success");
      } catch (e) {
        (0, _ajaxError.popupAjaxError)(e);
      }
    }
    newTopicSelected(topic) {
      this.topicId = topic.id;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "flash", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "topicId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "send", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "send"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "newTopicSelected", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "newTopicSelected"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TestIntegration);
});