define("discourse/plugins/discourse-chat-integration/admin/models/rule", ["exports", "@glimmer/tracking", "discourse/models/category", "discourse/models/rest", "I18n"], function (_exports, _tracking, _category, _rest, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let Rule = _exports.default = (_class = class Rule extends _rest.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "type", _descriptor, this);
      _initializerDefineProperty(this, "category_id", _descriptor2, this);
      _initializerDefineProperty(this, "tags", _descriptor3, this);
      _initializerDefineProperty(this, "channel_id", _descriptor4, this);
      _initializerDefineProperty(this, "filter", _descriptor5, this);
      _initializerDefineProperty(this, "error_key", _descriptor6, this);
      _defineProperty(this, "available_types", [{
        id: "normal",
        name: _I18n.default.t("chat_integration.type.normal")
      }, {
        id: "group_message",
        name: _I18n.default.t("chat_integration.type.group_message")
      }, {
        id: "group_mention",
        name: _I18n.default.t("chat_integration.type.group_mention")
      }]);
    }
    get available_filters() {
      const available = [];
      const provider = this.channel.provider;
      if (provider === "slack") {
        available.push({
          id: "thread",
          name: _I18n.default.t("chat_integration.filter.thread"),
          icon: "chevron-right"
        });
      }
      available.push({
        id: "watch",
        name: _I18n.default.t("chat_integration.filter.watch"),
        icon: "exclamation-circle"
      }, {
        id: "follow",
        name: _I18n.default.t("chat_integration.filter.follow"),
        icon: "circle"
      }, {
        id: "tag_added",
        name: _I18n.default.t("chat_integration.filter.tag_added"),
        icon: "tag"
      }, {
        id: "mute",
        name: _I18n.default.t("chat_integration.filter.mute"),
        icon: "times-circle"
      });
      return available;
    }
    get category() {
      const categoryId = this.category_id;
      if (categoryId) {
        return _category.default.findById(categoryId);
      } else {
        return false;
      }
    }
    get filterName() {
      return _I18n.default.t(`chat_integration.filter.${this.filter}`);
    }
    updateProperties() {
      return this.getProperties(["type", "category_id", "group_id", "tags", "filter"]);
    }
    createProperties() {
      return this.getProperties(["type", "channel_id", "category_id", "group_id", "tags", "filter"]);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "type", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "normal";
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "category_id", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "tags", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "channel_id", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "filter", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "watch";
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "error_key", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  })), _class);
});